<template>
  <main>
    <!-- content home page -->
    <div class="home-content container"
         :class="{'padding-webview' : checkWebview(), 'padding-has-caution': isHasCaution }">
      <sidebar class="pc"/>

      <div class="main-content">
        <div class="gmo">
          <picture>
            <source media="(min-width:768px)" srcset="../assets/images/gmo-pc.png">
            <source media="(max-width:499px)" srcset="../assets/images/gmo-sp.png">
            <img src="../assets/images/gmo-pc.png" alt="マイページの見方" class="switching" @click="redirectToSurveyWallPage()">
          </picture>
        </div>

        <!-- header -->
        <div class="has-bg">
          <!-- sidebar on sp -->
          <button @click="showSearchModal"
                  v-if="!checkWebview()"
                  class="show-sidebar">プログラムをさがす<img src="../assets/images/icon/arrow-down-black.png" alt="icon"/>
          </button>

          <!-- title feature -->
          <h2>特集一覧</h2>
        </div>

        <!-- list feature -->
        <ListFeature class="list-feature"
                     v-if="featureList && featureList.length > 0"
                     :list="featureList" />

        <!-- NOTE DATA EMPTY -->
        <p class="data-empty" v-else-if="rendered">該当するデータが見つかりませんでした。</p>

        <!-- pagination -->
        <Pagination :total-page="pagination.totalPages"
                    @change-page = changePage
                    v-if="pagination && rendered"
                    :current-page="pagination.currentPage" />

        <!-- list ranking adv -->
        <ListRanking :list-cate="rankingList" />
      </div>
    </div>
  </main>
</template>

<script>
// store
import store from '@/store'
import { mapActions, mapState } from 'vuex'

// component
import ListFeature from '@/components/ListFeature'
import ListRanking from '@/components/home/ListRanking'
import Sidebar from '@/layouts/Sidebar'
import Pagination from '@/components/Pagination'
import Common from '@/mixins/common.mixin'
import GMOResearch from '@/mixins/gmoResearch.mixin'

export default {
  name: 'FeatureList',

  mixins: [Common, GMOResearch],

  components: {
    Pagination,
    Sidebar,
    ListRanking,
    ListFeature
  },

  computed: {
    ...mapState('feature', ['featureList', 'pagination']),
    ...mapState('category', ['categoryListTitle']),
    ...mapState('categoryRanking', ['rankingList']),
    ...mapState('auth', ['profile']),
    isHasCaution () {
      return !this.checkWebview() && ((this.profile && this.profile.skip_regis_info) || this.checkMobile())
    }
  },

  async created () {
    const params = {
      page: 1,
      perPage: 12,
      orderBy: 'order',
      sortedBy: 'asc'
    }
    await store.dispatch('feature/getListFeature', params)
    this.rendered = true
  },

  async mounted () {
    // hide app's loading after page render
    if (Common.methods.checkWebview()) {
      Common.methods.triggerEventApp('PAGE_RENDERED', {})
    }

    await store.dispatch('categoryRanking/getList', { with: 'category,advertiseRankings.advertise' })

    this.pageActive = this.pagination.currentPage
  },

  data () {
    return {
      params: {
        page: 1,
        perPage: 12,
        orderBy: 'order',
        sortedBy: 'asc'
      },
      pageActive: 1,
      rendered: false
    }
  },

  methods: {
    ...mapActions('feature', ['getListFeature']),

    showSearchModal () {
      this.$emit('openSearch')
    },

    changePage (page) {
      this.params = {
        ...this.params,
        page: page
      }

      this.pageActive = page
      this.fetchList(this.params)
    },

    // FETCH LIST
    fetchList (params = {}) {
      this.getListFeature(params)

      // scroll to top when change page
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }
}
</script>

<style lang="scss" scoped>
.home-content {
  background: #ffffff;
  display: flex;
  align-items: flex-start;
  padding-top: 93px;
  padding-bottom: 96px;
  @media #{$info-phone} {
    display: block;
    padding-top: 45px;
    padding-bottom: 72px;
  }
  &.padding-has-caution {
    @media #{$info-phone} {
      padding-top: 92px;
    }
  }
  &.padding-webview {
    padding-top: 0;
    padding-bottom: 20px;
  }
}
.main-content {
  flex: 1;
  h2 {
    @include font-size(36px);
    font-family: $font-family-title;
    font-weight: normal;
    padding-bottom: 21px;
    color: #7C934C;
    border-bottom: 2px solid #CACBC6;
    position: relative;
    margin-bottom: 51px;
    line-height: 1;
    @media #{$info-phone} {
      margin-bottom: 20px;
      font-size: 32px;
      border-color: transparent;
    }
    &:after {
      content: '';
      position: absolute;
      width: 113px;
      height: 6px;
      background: #7C934C;
      left: 0;
      bottom: -2px;
      @media #{$info-phone} {
        width: 100px;
        bottom: 0;
      }
    }
    &:before {
      @media #{$info-phone} {
        content: '';
        position: absolute;
        height: 2px;
        left: -100%;
        right: -100%;
        bottom: 0;
        background: #CACBC6;
      }
    }
  }
  .has-bg {
    width: 100%;
    position: relative;
    z-index: 1;
    &:after {
      @media #{$info-phone} {
        content: '';
        position: absolute;
        width: -100%;
        height: 100%;
        bottom: 0;
        left: -100%;
        right: -100%;
        background: #F5F5F5;
        z-index: -1;
      }
    }
    @media #{$info-phone} {
      padding-top: 18px;
    }
  }
  .show-sidebar {
    font-family: $font-family-title;
    width: 100%;
    height: 44px;
    border-radius: 22px;
    box-shadow: 0 1px 4px rgba(0, 0, 0 , 0.16);
    background: #ffffff;
    display: none;
    align-items: center;
    justify-content: center;
    position: relative;
    @include font-size(16px);
    margin-bottom: 41px;
    @media #{$info-phone} {
      display: flex;
      font-size: 16px;
    }
    img {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .list-feature {
    margin-bottom: 45px;
    @media #{$info-phone} {
      margin-bottom: 56px;
    }
  }
  .pagination-common {
    margin-bottom: 96px;
    @media #{$info-phone} {
      margin-bottom: 72px;
    }
  }
}

.gmo {
  cursor: pointer;
  margin-bottom: 40px;

  @media #{$info-phone} {
    margin: 20px 0;
  }

  img {
    width: 100%;
  }
}

.data-empty {
  font-weight: bold;
  margin-bottom: 50px;
}
</style>
