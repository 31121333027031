<template>
  <div class="list-ranking-feature" v-if="list && list.length">
    <a v-for="item in list"
       @click="handleRedirectUrl(item.redirect_url)"
       :key="item.id">
        <img :src="item.file ? item.file.path : require('../assets/images/img-default.jpeg')"
             :alt="item.title">
    </a>
  </div>
</template>

<script>
import Common from '@/mixins/common.mixin'
import { PAGE_NATIVE } from '@/enum/title-header'
import { mapState } from 'vuex'

export default {
  name: 'ListFeature',

  props: ['list'],

  mixins: [Common],

  computed: {
    ...mapState('auth', ['token'])
  },

  methods: {
    checkIsCategoryList (url) {
      const routeCateList = ['category', 'survey', 'receipt']
      const isCateList = !!routeCateList.find(route => url.includes(route))
      return isCateList
    },
    handleRedirectUrl (url) {
      //  CHECK URL INTERNAL OR EXTERNAL
      const parseUrl = new URL(url)
      // REDIRECT PAGE NATIVE
      let flagPageNative = false
      PAGE_NATIVE.forEach((elm) => {
        if (elm.path === parseUrl.pathname) {
          flagPageNative = true
        }
      })

      if (this.hostName.includes(parseUrl.origin)) {
        if (flagPageNative) {
          const namePageNative = PAGE_NATIVE.find(x => x.path === parseUrl.pathname).name
          // CHECK URL IS LINK TO LOGIN OR REGISTER
          if (this.token &&
            (namePageNative === 'Login' || namePageNative === 'Register')) {
            this.openPageNative('Index')
          } else if (!this.token && namePageNative !== 'Index' && namePageNative !== 'Register') {
            this.openPageNative('Login')
          } else this.openPageNative(namePageNative)
        } else {
          if (!this.checkWebview()) {
            if (url.includes('/lp/') || this.checkIsCategoryList(url)) {
              window.open(url, '_self')
            } else if (url.includes('/cp/')) {
              window.open(url, '_blank')
            } else {
              this.$router.push({ path: parseUrl.pathname })
            }
          } else {
            // IF URL INTERNAL OPEN IN WEBVIEW
            if (this.checkWebview()) {
              const routeTriggerEventApp = ['feature-list', 'receipt', 'survey']
              let routeName = routeTriggerEventApp.find(route => url.includes(route))
              const isTriggerEventApp = !!routeName
              if (isTriggerEventApp) {
                if (routeName === 'feature-list') {
                  routeName = 'feature'
                }
                const nameEvent = `PAGE_REDIRECT_${routeName.toUpperCase()}`
                this.triggerEventApp(nameEvent, {})
              } else {
                this.triggerEventApp(
                  'OPEN_WEBVIEW',
                  { url: url }
                )
              }
            }
          }
        }
      } else if (this.checkWebview()) {
        this.triggerEventApp(
          'OPEN_BROWSER',
          { url: url }
        )
      } else window.open(url, '_blank')
    }
  },

  data () {
    return {
      hostName: process.env.VUE_APP_USER_DOMAIN,
      PAGE_NATIVE
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
